import { v4 } from 'uuid';
import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import type {
  AnalyticsPlayerEventNames,
  UserActionsEventNames,
  AnalyticsScreenEventNames,
  AnalyticsTapEventNames,
  AnalyticsPurchaseEventNames,
  AnalyticsSearchEventNames,
} from '~/services/analytics/events-names.ts';
import logger from '~/services/logger';

export const GoogleAnalyticsManager = ({
  gaTrackingId,
  analyticsSharedVars,
}: any): JSX.Element | null => {
  return gaTrackingId ? (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gaTrackingId}', ${JSON.stringify(
            analyticsSharedVars
          )});
            `,
        }}
      />
    </>
  ) : null;
};

export const GoogleTagManager = ({
  gtmTrackingId,
}: any): JSX.Element | null => {
  return gtmTrackingId ? (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmTrackingId}');`,
      }}
    />
  ) : null;
};

export const GoogleTagManagerNoScript = ({
  gtmTrackingId,
}: any): JSX.Element | null => {
  return gtmTrackingId ? (
    <noscript>
      <iframe
        title="googletagmanager"
        src={`https://www.googletagmanager.com/ns.html?id=${gtmTrackingId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  ) : null;
};

export function getZappClientUUID() {
  try {
    const uuidKey = 'zapp-uuid';

    const currentUuid = localStorage.getItem(uuidKey);

    const uuid = currentUuid || v4();

    if (!currentUuid) localStorage.setItem(uuidKey, JSON.stringify(uuid));

    return uuid;
  } catch (error) {
    return v4();
  }
}

export type EventName =
  | AnalyticsPlayerEventNames
  | UserActionsEventNames
  | AnalyticsScreenEventNames
  | AnalyticsTapEventNames
  | AnalyticsPurchaseEventNames
  | AnalyticsSearchEventNames;

export type AnalyticsData = {
  eventName: EventName;
  trackData: unknown;
};

export const getTapCellAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  UIComponent?: UIComponent,
  entry?: FeedEntry
) => {
  const {
    id: component_id,
    component_type,
    name: header_name,
    styles,
  } = UIComponent || {};

  const { id: entry_id, type, title, _entryIndex }: any = entry || {};

  return {
    eventName,
    trackData: {
      ...analyticsTrackData,
      component_type,
      component_id,
      header_name,
      cell_style: styles?.cell_plugin_configuration_id,
      item_type: type?.value,
      item_number: _entryIndex,
      item_name: title,
      item_id: entry_id,
    },
  };
};

export const getNavItemAnalyticsData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData?: any
) => {
  try {
    const {
      version,
      build_number,
      bundle_id,
      sdk_version,
      layout_id,
      zapp_platform,
      analyticsCustomProperties,
    } = analyticsTrackData;

    return {
      eventName,
      trackData: {
        version,
        build_number,
        bundle_id,
        sdk_version,
        layout_id,
        zapp_platform,
        analyticsCustomProperties,
        ...additionalData,
      },
    };
  } catch (error: any) {
    logger.info(`getNavItemAnalyticsData: ${error.message}`);
    return {
      eventName,
      trackData: {},
    };
  }
};

export const getPurchaseAnalyticData = (
  eventName: EventName,
  analyticsTrackData: any,
  additionalData?: any
) => {
  try {
    const {
      version,
      build_number,
      bundle_id,
      sdk_version,
      layout_id,
      zapp_platform,
    } = analyticsTrackData;

    const { offer, currency, totalPrice, offerId, purchaseType, offerV2 } =
      additionalData;

    if (eventName === 'purchase') {
      return {
        eventName,
        trackData: {
          currency: currency || offerV2?.price?.currency,
          value: totalPrice || offerV2?.price?.amount,
          transaction_id: offerId || offerV2?.longId,
          items: [
            {
              item_id: offerId || offerV2?.longId,
              item_name: offer?.title || offerV2?.title,
            },
          ],
        },
      };
    }

    const purchase_price =
      totalPrice && currency
        ? `${totalPrice} ${currency}`
        : `${offerV2?.price?.amount} ${offerV2?.price?.currency}`;
    return {
      eventName,
      trackData: {
        version,
        build_number,
        bundle_id,
        sdk_version,
        layout_id,
        zapp_platform,
        currency_code: currency || offerV2?.price?.currency,
        purchase_id: offerId || offerV2?.longId,
        price_numeric: totalPrice || offerV2?.price?.amount,
        purchase_price: purchase_price,
        purchase_type: purchaseType || offerV2?.type,
        purchase_name: offer?.title || offerV2?.title,
        screen_name: additionalData?.screenName,
      },
    };
  } catch (error: any) {
    logger.info(`getPurchaseAnalyticData: ${error.message}`);
    return {
      eventName,
      trackData: {},
    };
  }
};
